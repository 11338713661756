<template>
  <div class="common_sub_container">
    <section>
      <div class="address_book_primary_view">
        <h3>Upload Job Status File</h3>
        <p>Choose the Job Status upload file. Please remember only .csv files are accepted and it should be less than 2mb in size.</p>
        <p>You can download sample .csv format <a :href="sample_url" download>here</a></p>
        <!-- I created -->
        <label class="bt_upload_file">
          CHOOSE A FILE
          <input type="file" ref="myFiles" @change="handleFileUpload" />
        </label>

        <!-- I created -->
      </div>
    </section>
    <section></section>
  </div>
</template>
<script>
export default {
  name: "BulkBooking",
  components: {},
  created(){
    this.$emit("breadCrumbData",["Invoice Upload"]);
    this.sample_url = process.env.VUE_APP_API_URL + "/api/booking/bulkbooking/sample?token="+localStorage.token;
  },
  data() {
    return {
      files: "",
      sample_url:""
    };
  },
  methods: {
    handleFileUpload() {
      this.files = this.$refs.myFiles.files[0];
      let formData = new FormData();
      formData.append("sample_file", this.files);

      this.axios
        .post("/api/booking/hub/bulkbooking", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })

        .then(response => {
          this.toast.success(response.data.msg);
          // this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          // this.toast.error(error.response.data.msg);
          // this.$router.go(this.$router.currentRoute);
        });
    }
  }
};
</script>

<style  lang="scss"  >
</style>